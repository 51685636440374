import React, { useEffect } from "react";
import { FaLaptop } from "react-icons/fa";
// import Pdf from "../document/react.pdf";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Pricing.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

const ComputerFundamentalsComponent = ({ courseData }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: "Microsoft Office Training in Abuja, Nigeria", // Replace with actual name
    description: courseData.description, // Replace with actual description of the course
    provider: {
      "@type": "Organization",
      name: "GMTsoftware",
    },
    url: courseData.url, // Replace with actual URL of the course page
    sameAs: courseData.sameAs, // Replace with actual social media links
    image: courseData.image,
    offers: {
      "@type": "Offer",
      priceCurrency: "NGN", // Nigerian Naira
      price: 85000, // Adjust the price as needed
      availability: "https://schema.org/InStock",
      category: "Course",
    }, // Replace with actual image URL representing the course
    educationalUse: "FormalEducation", // Specify formal education use
    hasPart: [
      // Add course modules here
      {
        "@type": "CourseModule",
        name: "Microsoft Word Essentials",
        description: "Learn to create, edit, and format documents effectively.",
      },
      {
        "@type": "CourseModule",
        name: "Microsoft Excel Fundamentals",
        description: "Master data analysis, spreadsheets, and formulas.",
      },
      {
        "@type": "CourseModule",
        name: "Microsoft PowerPoint Presentations",
        description:
          "Create engaging presentations with effective visuals and storytelling.",
      },
      // Add more course modules as needed, following the same format
    ],
    hasCourseInstance: [
      {
        "@type": "CourseInstance",
        name: "Microsoft Office Training in Abuja, Nigeria", // Specific instance name
        startDate: "2024-01-01", // Start date of the instance
        endDate: "2024-12-01", // End date of the instance
        location: {
          "@type": "Place",
          name: "GMTsoftware Computer Training School Abuja Office", // Location name (optional)
        },
      },
      {
        // Online self-paced course that takes 2 days to complete.
        "@type": "CourseInstance",
        courseMode: "Blended",
        courseWorkload: "P2D",
      },
      // Add more objects for additional instances with different details
    ],
  };
  useEffect(() => {
    document.title =
      "Mirosoft Excel, Microsoft Office, Microsoft Power Point Training In Abuja | React";
  }, []);
  return (
    <>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      <Navbar />
      <div className="container-fluid bg-dark text-white">
        <div className="container py-5 header_one">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p
                style={{
                  backgroundColor: "rgb(29, 78, 149)",
                  color: "#fff",
                  display: "inline",
                }}
              >
                LEARN FROM CERTIFIED MICROSOFT TRAINERS
              </p>
              <h1 className="text-white">
                Computer Fundamentals & Microsoft Office Suite Training
              </h1>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Master the essential skills required to effectively use
                computers and the Microsoft Office Suite including Word, Excel,
                PowerPoint, Outlook, and Paint. Get equipped for today's digital
                workforce.
              </p>
              <div className="container my-4">
                <a
                  href="/Apply-for-GMTsoftware-Training"
                  className="ml-3 btn btn-primary btn-md"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "rgb(29, 78, 149)",
                  }}
                >
                  Apply Now
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img
                src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixid=MXwxMjA3fDB8MHxwaGUlLXBhZ2V8MXx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                alt="Computer Fundamentals"
                className="img-fluid shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="py-4 w-75" style={{ fontWeight: 1000 }}>
          Whether you're new to computers or looking to enhance your existing
          skills, this course offers the foundational knowledge needed to excel
          in a technology-driven world.
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Build a Strong Foundation in Computer Skills</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Learn the basics of computing, including navigating the operating
              system, managing files, and understanding hardware components.
              These core skills will prepare you to confidently operate any
              computer system.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Master Microsoft Office Tools</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Gain proficiency in Microsoft Office, the world's leading
              productivity suite. Learn to create and edit documents in Word,
              analyze data in Excel, design presentations in PowerPoint, manage
              emails with Outlook, and explore creativity with Paint.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
            <h4>Flexible Learning Options for Busy Individuals</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Join this course online or attend in person at our modern training
              facilities. Learn at your own pace, whether you are a complete
              beginner or someone looking to enhance your digital skills.
            </p>
          </div>
        </div>
      </div>

      <div className="curriculum-section front_img shadow-lg">
        <div className="container text-white">
          <div className="row flex-row-reverse">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
              <h2
                className="display-2 font-weight-bolder pt-5"
                style={{ color: "#fff" }}
              >
                Comprehensive Curriculum Tailored for Success
              </h2>
              <h4 className="pt-5">
                <strong>
                  Real-World Applications with Microsoft Office Suite
                </strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Our curriculum focuses on practical, hands-on training to ensure
                you can apply what you've learned in real-life scenarios. From
                typing documents to handling complex spreadsheets, we cover it
                all.
              </p>
              <h4>
                <strong>
                  Excel in Word Processing, Spreadsheets, and Presentations
                </strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Learn to create professional documents, manage and analyze data,
                and deliver impactful presentations. Our comprehensive lessons
                in Word, Excel, and PowerPoint will boost your productivity and
                make you an asset in any workplace.
              </p>
              <h4>
                <strong>Get Started with Self-Paced Prep Lessons</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Begin your journey with introductory lessons that familiarize
                you with the basics of computer operation and Microsoft Office.
                These self-paced prep modules will set you up for success in the
                course.
              </p>
              {/* <div className="py-4 text-center">
                <a href={Pdf} className="btn btn-dark btn-sm-center">
                  Download Curriculum <br />
                  <span>
                    <FaDownload size={20} />
                  </span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center my-auto">
            <img
              src="./images/student_testimonial.png"
              alt="Student Testimonial"
              width="100"
              className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
            />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <strong>Office Administrator</strong> <br /> Michael Johnson
            </p>
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <em>
                “The Computer Fundamentals and Microsoft Office training helped
                me tremendously in my day-to-day tasks. I now feel confident
                navigating various software applications, making me more
                productive at work.”
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container p-5">
          <h2 className="text-center">Flexible Payment Options</h2>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Full Tuition</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Save by paying the full #5,000 upfront and get access to all
                course materials, including bonus tutorials.
              </p>
              <h4>#85,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Full Tuition
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Installments</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Divide the tuition into two or three easy payments, making it
                easier to budget for your education.
              </p>
              <h4>#60,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Per Installment
              </p>
            </div>
          </div>
        </div>
      </div>
      <IconContext.Provider value={{ color: "#fff", size: 64 }}>
        <div className="pricing__section">
          <div className="pricing__wrapper text-center">
            <h2 className="py-3 text-center">
              Fundamental Computer Literacy Course Prices
            </h2>
            <div className="pricing__container text-center">
              <a
                href="/Apply-for-GMTsoftware-Training"
                className="pricing__container-card text-center"
              >
                <div className="pricing__container-cardInfo">
                  <div className="icon1">
                    <FaLaptop />
                  </div>
                  <h3>Fundamental Computer Lit</h3>

                  <ul className="pricing__container-features">
                    <li>4 weeks</li>
                    <li>Abuja, Nigeria</li>
                  </ul>
                  <h4>Total</h4>
                  <h3>#85,000</h3>
                  <p>4 weeks</p>
                  <Link to="/Apply-for-GMTsoftware-Training">
                    <button className="btn bg-dark text-white">
                      Apply Now
                    </button>
                  </Link>
                </div>
              </a>
            </div>
          </div>
        </div>
      </IconContext.Provider>

      <div className="container text-center py-5">
        <h5>Need advice? Our Admissions team is here to help.</h5>
        <button className="btn btn-dark mb-2">
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="/Apply-for-GMTsoftware-Training"
          >
            Contact Admissions
          </a>
        </button>
      </div>

      <Footer />
    </>
  );
};

export default ComputerFundamentalsComponent;
