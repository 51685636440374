import React, { useEffect } from "react";
import { FaNetworkWired } from "react-icons/fa";
// import Pdf from "../document/react.pdf";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Pricing.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

const DotNetProgrammingComponent = () => {
  useEffect(() => {
    document.title =
      ".NET C# Programming, HTML5, Web API & .NET Core training in Abuja";
  }, []);
  return (
    <>
      <Navbar />
      <div className="container-fluid bg-dark text-white">
        <div className="container py-5 header_one">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p
                style={{
                  backgroundColor: "rgb(0, 123, 255)",
                  color: "#fff",
                  display: "inline",
                }}
              >
                LEARN FROM PROFESSIONAL DEVELOPERS
              </p>
              <h1 className="text-white">
                .NET C# Programming, HTML5, Web API & .NET Core
              </h1>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Become proficient in full-stack development with modern
                technologies like .NET C#, HTML5, Web API, and .NET Core. Learn
                how to build high-performance, scalable web applications from
                scratch.
              </p>
              <div className="container my-4">
                <Link
                  to="/sign-up"
                  className="ml-3 btn btn-primary btn-md"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "rgb(0, 123, 255)",
                  }}
                >
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img
                src="https://images.unsplash.com/photo-1535223289827-42f1e9919769?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60"
                alt=".NET Programming"
                className="img-fluid shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="py-4 w-75" style={{ fontWeight: 1000 }}>
          With the growing demand for web and application development,
          full-stack developers proficient in .NET, C#, and web technologies are
          highly sought after.
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Develop Powerful Applications with .NET C#</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Learn the fundamentals of programming with C#, one of the most
              versatile and widely used languages in software development. Build
              high-quality, robust applications using the .NET framework.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Master Front-End Development with HTML5</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Dive into HTML5 and learn how to build modern, responsive websites
              and user interfaces. Understand the latest HTML5 standards and
              best practices for creating dynamic and engaging web pages.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
            <h4>Build Scalable APIs with Web API & .NET Core</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Gain hands-on experience in developing RESTful APIs using .NET
              Core and Web API frameworks. Learn how to create scalable and
              high-performance back-end services for modern web applications.
            </p>
          </div>
        </div>
      </div>

      <div className="curriculum-section front_img shadow-lg">
        <div className="container text-white">
          <div className="row flex-row-reverse">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
              <h2
                className="display-2 font-weight-bolder pt-5"
                style={{ color: "#fff" }}
              >
                Comprehensive Curriculum Designed for Full-Stack Developers
              </h2>
              <h4 className="pt-5">
                <strong>Real-World Projects and Applications</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Work on real-world projects where you will design and build
                full-stack web applications from front-end to back-end. Develop
                skills in C#, .NET Core, and Web API while integrating modern
                web technologies like HTML5.
              </p>
              <h4>
                <strong>Hands-On Experience with .NET Core and Web API</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Build and deploy scalable, cloud-based applications using .NET
                Core. Understand the structure of APIs and learn how to handle
                HTTP requests, authentication, and data management in your web
                services.
              </p>
              <h4>
                <strong>Learn at Your Own Pace with Self-Paced Modules</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Start with our self-paced prep lessons that cover basic to
                advanced topics in .NET programming, HTML5, and web services.
                Get hands-on with exercises designed to enhance your coding
                skills.
              </p>
              {/* <div className="py-4 text-center">
                <a href={Pdf} className="btn btn-dark btn-sm-center">
                  Download Curriculum <br />
                  <span>
                    <FaDownload size={20} />
                  </span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center my-auto">
            <img
              src="./images/student_testimonial.png"
              alt="Student Testimonial"
              width="100"
              className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
            />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <strong>Software Developer</strong> <br /> Emily Davis
            </p>
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <em>
                “The .NET and Web API training provided me with the practical
                skills needed to build scalable web applications. The hands-on
                approach made learning enjoyable and applicable to my career.”
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container p-5">
          <h2 className="text-center">Flexible Payment Options</h2>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Full Tuition</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Save 5% by paying the full #200,000 upfront and get access to
                advanced tutorials and additional resources.
              </p>
              <h4>#200,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Full Tuition
              </p>
            </div>
          </div>
        </div>
      </div>

      <IconContext.Provider value={{ color: "#fff", size: 64 }}>
        <div className="pricing__section">
          <div className="pricing__wrapper text-center">
            <h2 className="py-3 text-center">.Net C# Prices</h2>
            <div className="pricing__container text-center">
              <Link
                to="/sign-up"
                className="pricing__container-card text-center"
              >
                <div className="pricing__container-cardInfo">
                  <div className="icon1">
                    <FaNetworkWired />
                  </div>
                  <h3>.Net C#</h3>
                  <ul className="pricing__container-features">
                    <li>8 weeks</li>
                    <li>Abuja, Nigeria</li>
                  </ul>
                  <h4>Total</h4>
                  <h3>#200,000</h3>
                  <p>8 weeks</p>
                  <Link to="/signup">
                    <button className="btn bg-dark text-white">
                      Apply Now
                    </button>
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </IconContext.Provider>

      <div className="container text-center py-5">
        <h5>Need advice? Our Admissions team is here to help.</h5>
        <button className="btn btn-dark mb-2">
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:greatmindsoftware@gmail.com"
          >
            Contact Admissions
          </a>
        </button>
      </div>
      <Footer />
    </>
  );
};

export default DotNetProgrammingComponent;
