import Home from "./components/pages/HomePage/Home";
import Success from "./components/pages/Courses/success";
import Failure from "./components/pages/Courses/failure";
import Curriculum from "./components/pages/Curriculum/Curriculum";
import SignUp from "./components/pages/SignUp/SignUp";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Webdev from "./components/pages/Webdev/Webdev";
import UXdesigner from "./components/pages/Courses/UX designer";
import About from "./components/pages/About/About";
import FrontEnd from "./components/pages/Courses/FrontEnd";
import Fullstack from "./components/pages/Courses/Fullstack";
import Python from "./components/pages/Courses/Python";
import Cybersecurity from "./components/pages/Courses/Cybersecurity";
import Flutter from "./components/pages/Courses/Flutter";
import Companies from "./components/pages/Courses/Companies";
import Datascience from "./components/pages/Courses/Datascience";
import Digital from "./components/pages/Courses/Digital";
import Certificate from "./components/pages/Courses/Certificate";

// import Allcourses from "./Blog/Body/Allcourses";
// import Python2 from "./Blog/Body/Python";
// import Flutter2 from "./Blog/Body/Flutter";
// import Mernstack from "./Blog/Body/Mernstack";
// import UIUX from "./Blog/Body/UX";
// import UIUX2 from "./Blog/Body/UXDetails";
// import Pythondetails from "./Blog/Body/PythonDetails";
// import Flutterdetails from "./Blog/Body/Flutterdetails";
// import Fullstackblog from "./Blog/Body/Fullstackblog";
import Certificates from "./Certificate/certificate";
import Dashboard from "./Dashboard/Dashboard";
// import Homes from "./homeportal/Home";
import Login from "./SignIn/Login";
import Signup from "./SignUp/Signup";
import Tasks from "./Task/Task";
import Userprofile from "./Userprofile/Userprofile";
import Project from "./components/pages/Project/Project";
import PrivateRoute from "./utils/PrivateRoute";
import Gallery from "./components/pages/Gallery/Gallery";

import DotNetProgrammingComponent from "./components/pages/Courses/Net";
import ComputerFundamentalsComponent from "./components/pages/Courses/Microsoft";
import JavaProgrammingComponent from "./components/pages/Courses/Java";
import Webdev from "./components/pages/Webdev/Webdev";
// import Landingpage from "./components/pages/landingpage";

function App() {
  const courses = [
    {
      name: "Full Stack Web Development Training in Abuja, Nigeria",
      description:
        "Learn to build modern web applications from front-end to back-end in Abuja Nigeria.",
      url: "https://www.gmtsoftware.tech/Web-development-training-in-Abuja/",
      image:
        "https://www.logolynx.com/images/logolynx/b5/b5299d83a8f3d9fb3e33a57336b05aec.png",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Python Programming Training in Abuja",
      description:
        "Master Python, a versatile programming language, from experienced instructors in Abuja. Our comprehensive course covers fundamental concepts, data structures, algorithms, and real-world applications.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image:
        "https://images.pexels.com/photos/1181359/pexels-photo-1181359.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 150000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Python-Programming-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation", // Specify the educational use
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Python Fundamentals",
          description:
            "Learn basic Python syntax, data types, control flow, and functions.",
        },
        {
          "@type": "CourseModule",
          name: "Object-Oriented Programming (OOP)",
          description:
            "Understand OOP concepts like classes, objects, inheritance, and polymorphism.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Front-End Software Development Training in Abuja, Nigeria",
      description:
        "Master the art of creating visually appealing and interactive web interfaces with our comprehensive Front-End Software Development training.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image: "https://www.gmtsoftware.tech/images/second.jpg", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 150000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Frontend-Software-development-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "HTML5 and CSS3 Fundamentals",
          description:
            "Learn the building blocks of web design: HTML5 structure and CSS3 styling.",
        },
        {
          "@type": "CourseModule",
          name: "JavaScript Programming",
          description:
            "Master JavaScript for dynamic web interactions and client-side scripting.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Cybersecurity Training in Abuja, Nigeria",
      description:
        "Protect your digital assets with our comprehensive Cybersecurity training program. Learn essential skills to defend against cyber threats.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image: "https://www.gmtsoftware.tech/images/cyber.jpeg", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 300000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Cybersecurity-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Network Security Fundamentals",
          description:
            "Learn about network security concepts, vulnerabilities, and mitigation techniques.",
        },
        {
          "@type": "CourseModule",
          name: "Ethical Hacking and Penetration Testing",
          description:
            "Gain hands-on experience in identifying and exploiting vulnerabilities to enhance security.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Mobile Application Training in Abuja, Nigeria",
      description:
        "Learn to build native mobile apps for iOS and Android platforms with our comprehensive training program.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image:
        "https://www.logolynx.com/images/logolynx/b5/b5299d83a8f3d9fb3e33a57336b05aec.png", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 300000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Mobile-Application-Training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Mobile App Development Fundamentals",
          description:
            "Learn about mobile app development concepts, tools, and platforms.",
        },
        {
          "@type": "CourseModule",
          name: "Native App Development (iOS and Android)",
          description:
            "Develop native apps for iOS and Android using Swift and Kotlin.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Microsoft Office Training in Abuja, Nigeria",
      description:
        "Master essential Microsoft Office applications like Word, Excel, PowerPoint, and Outlook for productivity and efficiency.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image: "https://www.gmtsoftware.tech/images/femi1.jpg", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 85000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Microsoft-office-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Microsoft Word Essentials",
          description:
            "Learn to create, edit, and format documents effectively.",
        },
        {
          "@type": "CourseModule",
          name: "Microsoft Excel Fundamentals",
          description: "Master data analysis, spreadsheets, and formulas.",
        },
        {
          "@type": "CourseModule",
          name: "Microsoft PowerPoint Presentations",
          description:
            "Create engaging presentations with effective visuals and storytelling.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Java Programming Training in Abuja, Nigeria",
      description:
        "Master Java, a versatile programming language, for building robust applications. Our comprehensive training covers core concepts, object-oriented programming, and real-world projects.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image: "https://www.gmtsoftware.tech/images/femi1.jpg", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 250000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Java-programming-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/Gmtsoftware",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Java Fundamentals",
          description: "Learn core Java concepts, syntax, and data types.",
        },
        {
          "@type": "CourseModule",
          name: "Object-Oriented Programming (OOP)",
          description:
            "Understand OOP principles and apply them in Java programming.",
        },
        {
          "@type": "CourseModule",
          name: "Java Web Development",
          description:
            "Build web applications using Java technologies like Servlets, JSP, and Spring Framework.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "UI/UX Designer Training in Abuja, Nigeria",
      description:
        "Master the art of creating user-friendly and visually appealing interfaces with our UI/UX Design training program.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image: "https://www.gmtsoftware.tech/images/femi1.jpg", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 150000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/UI/UX-designer-Training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "User Research and Analysis",
          description:
            "Learn to understand user needs, conduct research, and create user personas.",
        },
        {
          "@type": "CourseModule",
          name: "Information Architecture and Wireframing",
          description:
            "Design effective information structures and create low-fidelity prototypes.",
        },
        {
          "@type": "CourseModule",
          name: "Visual Design and User Interface (UI) Design",
          description:
            "Create visually appealing and intuitive user interfaces.",
        },
        {
          "@type": "CourseModule",
          name: "User Experience (UX) Design",
          description:
            "Focus on improving the overall user experience through usability testing and iterative design.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Kids Coding Training in Abuja, Nigeria",
      description:
        "Spark your child's creativity and introduce them to the world of coding with our engaging and interactive Kids Coding training program.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image:
        "https://railsware.com/blog/wp-content/uploads/2018/10/Python-for-Data-Science-facebook.png", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 100000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Kids-Coding-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Introduction to Coding Concepts",
          description:
            "Learn basic programming concepts, algorithms, and problem-solving.",
        },
        {
          "@type": "CourseModule",
          name: "Scratch Programming",
          description:
            "Create interactive games and animations using the Scratch programming language.",
        },
        {
          "@type": "CourseModule",
          name: "Python for Kids",
          description:
            "Explore Python programming and its applications for creating games and projects.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Data Analysis Training in Abuja, Nigeria",
      description:
        "Master data analysis techniques and tools to extract valuable insights from data. Our comprehensive training program covers data cleaning, visualization, and statistical analysis.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image:
        "https://railsware.com/blog/wp-content/uploads/2018/10/Python-for-Data-Science-facebook.png", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 200000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/DataAnalysis-training-in-abuja/",
      sameAs: [
        "https://www.facebook.com/GmtsoftwareNG",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Data Cleaning and Preparation",
          description:
            "Learn techniques for cleaning, organizing, and preparing data for analysis.",
        },
        {
          "@type": "CourseModule",
          name: "Data Visualization",
          description:
            "Create effective visualizations to communicate data insights.",
        },
        {
          "@type": "CourseModule",
          name: "Statistical Analysis",
          description:
            "Apply statistical methods to analyze and interpret data.",
        },
        // Add more course modules as needed
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: "Digital Marketing Training in Abuja, Nigeria",
      description:
        "Master digital marketing strategies to promote your business online. Learn SEO, social media marketing, content marketing, and more.",
      provider: {
        "@type": "Organization",
        name: "GMTsoftware",
        url: "https://www.gmtsoftware.tech",
      },
      image:
        "https://th.bing.com/th/id/Rca70c92fe833f9049d88cff144845f44?rik=X9NXky6uV9rdAw&riu=http%3a%2f%2fi.huffpost.com%2fgen%2f1987421%2fimages%2fo-DIGITAL-MARKETING-facebook.jpg&ehk=Ed2uRcV%2bNYs5dEOOinurBvYJW2nq8kDPyAmd1Ye1jKg%3d&risl=&pid=ImgRaw", // Replace with your actual image URL
      offers: {
        "@type": "Offer",
        priceCurrency: "NGN", // Nigerian Naira
        price: 200000, // Adjust the price as needed
        availability: "https://schema.org/InStock",
      },
      url: "https://www.gmtsoftware.tech/Digital-Marketing-training-in-Abuja/",
      sameAs: [
        "https://www.facebook.com/gmtsoftware",
        "https://www.instagram.com/gmtsoftware/",
      ],
      educationalUse: "FormalEducation",
      hasPart: [
        {
          "@type": "CourseModule",
          name: "Search Engine Optimization (SEO)",
          description:
            "Learn techniques to improve your website's visibility in search engine results.",
        },
        {
          "@type": "CourseModule",
          name: "Social Media Marketing",
          description:
            "Master effective strategies for promoting your brand on social media platforms.",
        },
        {
          "@type": "CourseModule",
          name: "Content Marketing",
          description:
            "Create high-quality content that attracts and engages your target audience.",
        },
        {
          "@type": "CourseModule",
          name: "Email Marketing",
          description:
            "Build and manage email marketing campaigns to reach your subscribers.",
        },
        // Add more course modules as needed
      ],
    },
  ];
  return (
    <>
      <Router>
        <Routes>
          <Route path="/where-to-learn-tech-in-abuja" element={<Home />} />
          <Route path="/tech-school-in-abuja" element={<Curriculum />} />
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route
            path="/Web-development-training-in-Abuja"
            element={<Fullstack courseData={courses[0]} />}
          />
          <Route
            path="/Python-Programming-training-in-Abuja"
            element={<Python courseData={courses[1]} />}
          />
          <Route
            path="/FrontEnd-Software-development-training-in-Abuja"
            element={<FrontEnd courseData={courses[2]} />}
          />
          <Route
            path="/Cybersecurity-training-in-Abuja"
            element={<Cybersecurity courseData={courses[3]} />}
          />
          <Route
            path="/Mobile-Application-Training-in-Abuja"
            element={<Flutter courseData={courses[4]} />}
          />
          <Route
            path="/Microsoft-Office-training-in-Abuja"
            element={<ComputerFundamentalsComponent courseData={courses[5]} />}
          />
          <Route
            path="/Java-programming-training-in-Abuja"
            element={<JavaProgrammingComponent courseData={courses[6]} />}
          />
          <Route path="/Coding-Bootcamp-in-Abuja" element={<Curriculum />} />
          <Route path="/Apply-for-GMTsoftware-Training" element={<SignUp />} />
          <Route path="/careers" element={<Webdev />} />
          <Route
            path="/UI/UX-designer-Training-in-Abuja"
            element={<UXdesigner courseData={courses[7]} />}
          />
          <Route path="/Computer-Training-in-Abuja" element={<About />} />
          <Route
            path="/Kids-Coding-training-in-Abuja"
            element={<Companies courseData={courses[8]} />}
          />
          <Route
            path="/DataAnalysis-training-in-abuja"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route
            path="/Data-science-training-in-Abuja"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route
            path="/Digital-Marketing-training-in-Abuja"
            element={<Digital courseData={courses[10]} />}
          />
          <Route
            path="/blockchain-and-cryptocurrency-training-in-abuja-nigeria/"
            element={<Python courseData={courses[1]} />}
          />
          <Route
            path="/10-most-lucrative-money-making-digital-skills-in-nigeria-and-how-to-learn-them/"
            element={<Home />}
          />
          <Route
            path="/Cybersecurity-training-in-abuja"
            element={<Cybersecurity courseData={courses[3]} />}
          />
          <Route
            path="/c-sharp-training-in-Abuja"
            element={<DotNetProgrammingComponent />}
          />
          <Route
            path="/abuja-free-ict-and-computer-training/"
            element={<Home />}
          />
          <Route
            path="/advanced-analysts/"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route path="/courses/" element={<Home />} />
          <Route path="/data-skills-set/" element={<Home />} />
          <Route path="/Certificate" element={<Certificate />} />
          <Route
            path="/ui-ux-design-training-in-abuja-nigeria/"
            element={<UXdesigner courseData={courses[7]} />}
          />
          <Route
            path="/scholarship-on-ui-ux-web-design-and-development-training-in-abuja-nigeria/"
            element={<Curriculum />}
          />{" "}
          <Route path="/scratch-programming/" element={<Home />} />
          <Route path="/Professional-video-editors" element={<Home />} />
          <Route path="/practical-training" element={<Home />} />
          <Route
            path="/power-bi-training-in-abuja"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route
            path="/mean-stack"
            element={<Fullstack courseData={courses[0]} />}
          />
          <Route path="/Fundamentals-basics" element={<Home />} />
          <Route
            path="/digital-marketing-training/"
            element={<Digital courseData={courses[10]} />}
          />
          <Route path="/digital-literacy-workplace" element={<Home />} />
          <Route
            path="/artificial-intelligence-for-business-growth-masterclass-in-nigeria-ai-training"
            element={<Home />}
          />
          <Route
            path="/full-stack-web-development-training-in-abuja-nigeria"
            element={<Fullstack courseData={courses[0]} />}
          />
          <Route
            path="/ecommerce-website-design-training-in-nigeria-make-money-from-ecommerce-website"
            element={<Fullstack courseData={courses[0]} />}
          />
          <Route path="/our-trainings" element={<Curriculum />} />
          <Route path="/adobe-indesign-in-abuja" element={<Home />} />
          <Route path="/adobe-illustrator-in-abuja" element={<Home />} />
          <Route
            path="/marketing"
            element={<Digital courseData={courses[10]} />}
          />
          <Route path="/adobe-photoshop" element={<Home />} />
          <Route path="/adobe-illustrator-in-Abuja" element={<Home />} />
          <Route path="/3d-modelling-animation-in-abuja" element={<Home />} />
          <Route path="/ecommerce-design" element={<Home />} />
          <Route
            path="/business-analytic-in-abuja"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route path="/design-website-in-abuja/" element={<Home />} />
          <Route
            path="/motion-graphics-animation-in-abuja/"
            element={<Home />}
          />
          <Route
            path="/data-analysis"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route
            path="/c-plus-plus-programming-training-in-abuja-nigeria"
            element={<DotNetProgrammingComponent />}
          />
          <Route
            path="/python-for-cyber-security-and-ethical-hacking"
            element={<Cybersecurity courseData={courses[3]} />}
          />
          <Route
            path="/mern-stack-javascript-development-training-in-abuja-nigeria/"
            element={<Fullstack courseData={courses[0]} />}
          />
          <Route
            path="/devops-training-in-abuja-nigeria-complete-devops-engineer-course-in-abuja/"
            element={<Home />}
          />
          <Route path="/programming-fundamentals/" element={<Home />} />
          <Route
            path="/search-engine-optimization/"
            element={<Digital courseData={courses[10]} />}
          />
          <Route
            path="/html-css-javascript-training-abuja/"
            element={<FrontEnd courseData={courses[2]} />}
          />
          <Route path="/usage-professional-computers/" element={<Home />} />
          <Route
            path="/mobile-application-development-react-native/"
            element={<Home />}
          />
          <Route
            path="/machine-learning/"
            element={<Python courseData={courses[1]} />}
          />
          <Route
            path="/python-programming/"
            element={<Python courseData={courses[1]} />}
          />
          <Route
            path="/mobile-app-development-course/"
            element={<Flutter courseData={courses[4]} />}
          />
          <Route
            path="/how-to-become-a-motion-graphics-designer-in-nigeria/"
            element={<UXdesigner courseData={courses[7]} />}
          />
          <Route
            path="/data-science-training-course-in-abuja-nigeria/"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route
            path="/multiplatform-mobile-app-development-training-using-react-native-in-abuja-nigeria"
            element={<Flutter courseData={courses[4]} />}
          />
          <Route
            exact
            path="/data-skills-set"
            element={<Datascience courseData={courses[9]} />}
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/Software-training-institute-in-Abuja"
            element={<DotNetProgrammingComponent />}
          />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Tasks" element={<Tasks />} />
          </Route>
          <Route path="/Profile" element={<Userprofile />} />
          <Route path="/Certificates" element={<Certificates />} />
          <Route path="/Software-Project-in-Abuja" element={<Project />} />
          <Route path="/GMTsoftware-gallery" element={<Gallery />} />
          <Route
            path="*"
            element={<Navigate to="/Apply-for-GMTsoftware-Training" />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
