import React, { useEffect } from "react";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home6 from "./Home6";
import Program from "./Program";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar";

import Home5 from "./Home5";
import Home7 from "./Home7";
import "./Home.css";
import Timetable from "./Timetable";
import Footer from "../Footer/Footer";

// import Video from "../../Video/video.mp4"; Video is not working

function Home() {
  useEffect(() => {
    document.title =
      "Computer Training School in Abuja | Software Training Institute in Abuja ";
  }, []);
  return (
    <>
      <Navbar />
      <div class="marquee">
        <div>
          I'm excited to announce that GMTsoftware will be Starting
          Cybersecurity/Virtual Assistance/ 3d and 2d modeling Class
        </div>
      </div>
      <div className="main_container" style={{ marginTop: "0px" }}>
        <div className="">
          {/* <div className="overlay"></div> */}
          <header>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="4"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="5"
                ></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100"
                    src="./images/Slide1.jpeg"
                    alt="First slide"
                  />
                  <div class="carousel-caption  my-auto d-md-block">
                    <i>
                      <h2>
                        Enroll For CyberSecurity/Data Analytics/Software
                        Development 2024
                      </h2>
                    </i>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="./images/anniversary.jpeg"
                    alt="Second slide"
                  />
                  <div class="carousel-caption  my-auto d-md-block">
                    <i>
                      <h6>
                        Enroll For Virtual Assistance/3d and 2d Animation/ Core
                        Java Prgramming 2024
                      </h6>
                    </i>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="./images/team1.jpeg"
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="./images/slide2.jpeg"
                    alt="Fourth slide"
                  />
                  <div class="carousel-caption  my-auto d-md-block">
                    <i>
                      <h2>Graduating Student 2022</h2>
                    </i>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="./images/dashboard.png"
                    alt="Fifth slide"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Prev</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only"></span>
              </a>
            </div>
            <center>
              <img src="./gmt.gif" alt="gmt" />
            </center>
          </header>
        </div>

        {/* second container */}

        <div className="container">
          <div className="row">
            <div
              className="shadow-lg individual col-sm-12 col-md my-4 col-lg col-xl py-3 bg-white card"
              style={{ marginRight: "10px" }}
            >
              <div className="card-body">
                <h4 className="card-Subtitle" style={{ color: "#8D1212" }}>
                  Computer Training Model in Abuja: Peer-to-Peer Training System
                </h4>
                <p
                  className="card-text pb-5"
                  style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
                >
                  Our industry-lead 8 to 14-week bootcamps are designed to help
                  you become a work-ready software developer with outstanding
                  career prospects.You build real-world projects, we assist you
                  to build a career portfolio that we give you a confidence to
                  showcase your skills to your potential employer.
                </p>
              </div>
            </div>

            <div
              className="shadow-lg business col-sm-12 col-md col-lg col-xl py-2 bg-white card"
              style={{ marginLeft: "10px" }}
            >
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/795862193?h=0ae901a9ec"
                height="240"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#8D1212" }}>
                  Fullstack Developer: Punch Group LLC USA
                </h5>
                <p
                  className="card-text pb-5"
                  style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
                >
                  We know that choosing a boot camp is a big decision that can
                  change the course of your life, so who better to get advice
                  from than participants who have been right where you are? Hear
                  the testimonials above to hear directly from a learners and
                  alumni.
                </p>

                <a
                  href="https://gmtsoftware.tech/Apply-for-GMTsoftware-Training"
                  className="card-link"
                >
                  {" "}
                  <button className="btn btn-warning text-light">
                    Get Started &#8250;
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="shadow-lg business col-sm-12 col-md col-lg col-xl py-2 bg-white card"
              style={{ marginLeft: "10px" }}
            >
              <Timetable />
              <h3>
                For more info, call 09124041937 | 08160197959 or email
                contact@gmtsoftware.tech."
              </h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="shadow-lg business col-sm-12 col-md col-lg col-xl py-5 bg-white card"
              style={{ marginRight: "10px" }}
            >
              {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/795862193?h=0ae901a9ec" height="240" frameborder="0" allowfullscreen></iframe> */}
              {/* <lite-vimeo videoid="795862193"></lite-vimeo> */}
              {/* <iframe
                title="player"
                src="./img/digitalagency.mp4"
                allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                height="240px"
                muted
              ></iframe> */}
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#8D1212" }}>
                  GMTsoftware Digital Agency,
                </h5>
                <h6 style={{ color: "#8D1212" }}>
                  A subsidiary of GMTsoftware, We are the leading technology Hub
                  In Nigeria
                </h6>
                <p
                  className="card-text pb-1"
                  style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
                >
                  we are your one-stop point for all your technology needs. We
                  provide you with business solutions that are streamlined to
                  suit your diverse technology needs, achieve your business
                  objectives, improve your productivity & efficiency, and
                  increase your bottom-line growth. Our primary focus is to
                  provide solutions and services to organizations of all sizes
                  across all sectors of the economy.
                </p>
                <p
                  className="card-text pb-2"
                  style={{ lineHeight: "1.2rem", fontSize: "0.8rem" }}
                >
                  To learn more about how our digital agency can help grow your
                  business, visit our website
                </p>

                <a
                  href="https://digitalagency.gmtsoftware.tech/"
                  className="card-link"
                >
                  {" "}
                  <button
                    style={{ background: "#8D1212" }}
                    className="btn btn-danger text-light"
                  >
                    Digital agency &#8250;
                  </button>
                </a>
              </div>
            </div>
            <div
              className="shadow-lg individual col-sm-12 col-md my-4 col-lg col-xl py-3 bg-white card"
              style={{ marginLeft: "10px" }}
            >
              <div className="card-body">
                <h6
                  className="card-title text-align-center"
                  style={{ color: "#8D1212" }}
                >
                  Latest Job alert(June 2024)
                </h6>
                <img
                  src="./images/abrah.jpg"
                  className=" col-md col-lg col-xl "
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        {/* third */}

        <div className="container py-5">
          <div className="row">
            <div className="col-md-7 col-lg-7 col-xl-7">
              <img className="img-fluid" src="./images/dev2.jpg" alt="img" />
            </div>
            <div className=" col-md-5 col-lg-5 col-xl-5 d-none d-md-block icoo">
              <div className="row ">
                <img
                  className="col-md-7 col-lg-7 col-xl-7 img-fluid"
                  src="./images/third.jpg"
                  alt="img"
                />
                <img
                  className="col-md-5 col-lg-5 col-xl-5 img-fluid"
                  src="./images/martins.jpg"
                  alt="img"
                />
                <img
                  className=" p-4 col-md-10 col-lg-10 col-xl-10 img-fluid"
                  src="./img/5.jpg"
                  style={{ zIndex: "1" }}
                  alt="img"
                />
              </div>
              <div className="ico"></div>
            </div>
          </div>
        </div>

        <div className="container north">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-1 col-xl-6">
              <h1
                className="display-4 py-2 font-weight-bold"
                style={{ fontWeight: "bolder", color: "#8D1212" }}
              >
                GMTsoftware isn’t a course choice, it’s a career decision.
              </h1>
              <p
                className="lead py-2 font-weight-bold"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                You don’t need any coding experience to get a place at
                GMTsoftware.
              </p>
              <p
                className="mb-5"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                What you do need is an aptitude and passion for problem-solving
                and commitment to kick-start a fulfilling new career. If you can
                show these, our bootcamps will offer you the support and
                training you need to succeed.
              </p>
            </div>
          </div>
        </div>

        <div className="container north">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img src="images/cbd.jpg" alt="img" className="img-fluid"></img>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h1
                className="display-4 py-2 font-weight-bold"
                style={{ fontWeight: "bolder", color: "#8D1212" }}
              >
                Experience our coding bootcamp in Theodak Plaza, Central
                Business District, Abuja.
              </h1>
              <p
                className="lead font-weight-bold"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                Abuja is becoming a major tech hub thanks to its very active
                startup community and the presence of leading centers for
                artificial intelligence research attracting top talent and
                investments.
              </p>
              <p
                className="mb-5"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                {" "}
                The city is also recognized as one of the world’s most welcoming
                cities for tourists. It is distinguished by a unique
                multicultural openness which is a major component of Abuja's
                identity. Our coding bootcamp in Abuja is an amazing experience
                in an immersive coding school, offering not only a top-notch
                curriculum but an incredible human experience. We are proud to
                be part of Abuja's ecosystem since 2020 and to see our graduates
                now being key players in this amazing tech community's
                development.
              </p>
              <Link to="https://en.wikipedia.org/wiki/Abuja" target="_blank">
                <button
                  style={{ background: "#8D1212" }}
                  className="btn btn-danger mb-5"
                >
                  Read More &#8250;
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* End of main container */}
      </div>

      {/* We help to develop the developers your business needs. */}
      <div className="container py-5">
        <div className="row">
          <div className=" col-md-5 col-lg-5 col-xl-5 d-none d-md-block icoo">
            <div className="row ">
              <img
                style={{ transform: "rotate(30deg)", zIndex: "-1" }}
                className="col-md-6 col-lg-6 col-xl-6 img-thumbnail img-fluid"
                src="./images/chukwudi.jpg"
                alt="img"
              />
              <img
                className="col-md-6 col-lg-6 col-xl-6 img-fluid"
                src="./images/seventh.jpg"
                alt="img"
              />
              <img
                className=" p-4 col-md-10 col-lg-10 col-xl-10 img-fluid"
                src="./images/dashboard.jpg"
                style={{ zIndex: "-2" }}
                alt="img"
              />
            </div>

            {/* <div className="ico">

              <GoTriangleDown size={120} color="yellow" />
            </div> */}
          </div>
          <div className="col-md-7 col-lg-7 col-xl-7">
            <img className="img-fluid" src="./images/front.jpg" alt="img" />
          </div>
        </div>
      </div>

      {/* We help to develop the developers your business needs. */}

      <div className="container">
        <div className="row d-flex flex-row-reverse mx-auto">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 ">
            <h1
              className="display-4 font-weight-bolder"
              style={{ fontWeight: "bolder", color: "#8D1212" }}
            >
              We help to develop the developers your business needs.
            </h1>
            <p
              className="lead py-2 font-weight-bold"
              style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
            >
              Need to seamlessly onboard skilled new software developers, or
              grow, upskill or reskill your existing team?
            </p>
            <p
              className="mb-5"
              style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
            >
              We we have the expertise, support and services that you need.
              We’re industry-led, with a curriculum that’s firmly focused on
              your business needs.
            </p>

            <a href="https://gmtsoftware.tech/Web-development-training-in-Abuja">
              <button
                style={{ background: "#8D1212" }}
                className="btn btn-danger mb-5"
              >
                View courses &#8250;
              </button>
            </a>
          </div>
        </div>
      </div>

      <Home2 />
      <Home6 />
      <Home7 />
      <Home5 />
      <Program />
      <Home3 />

      {/* Carousel */}
      <div className="">
        <div
          className="container-fluid"
          style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
        ></div>
      </div>

      {/* Twitter */}
      <div className="container ">
        <div className="py-5">
          <Link
            className="twitter-timeline"
            data-width="1300"
            data-height="600"
            target="_blank"
            to="https://help.instagram.com/620154495870484/?helpref=uf_share"
            alt="img"
          >
            Tweets by GMTsoftware
          </Link>
          <br />
          <Link
            to="https://twitter.com/intent/tweet?button_hashtag=gmtsoftware&ref_src=twsrc%5Etfw"
            className="twitter-hashtag-button"
            data-size="large"
            data-related="Bayo4realZz"
            target="_blank"
            data-show-count="false"
            alt="img"
          >
            Tweet #gmtsoftware
          </Link>
          <br />

          <Link
            to="https://twitter.com/GMTSOFTWARE?ref_src=twsrc%5Etfw"
            target="_blank"
            data-size="large"
            className="twitter-follow-button"
            data-show-count="false"
          >
            Follow @GMTsoftware
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
