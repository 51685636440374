import React, { useEffect } from "react";
import { FaJava } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Pricing.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

const JavaProgrammingComponent = ({ courseData }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: "Java Programming Training in Abuja, Nigeria", // Replace with actual name
    description: courseData.description, // Replace with actual description of the course
    provider: {
      "@type": "Organization",
      name: "GMTsoftware",
    },
    url: courseData.url, // Replace with actual URL of the course page
    sameAs: courseData.sameAs, // Replace with actual social media links
    image: courseData.image,
    offers: {
      "@type": "Offer",
      priceCurrency: "NGN", // Nigerian Naira
      price: 150000, // Adjust the price as needed
      availability: "https://schema.org/InStock",
      category: "Course",
    }, // Replace with actual image URL representing the course
    educationalUse: "FormalEducation", // Specify formal education use
    hasPart: [
      // Add course modules here
      {
        "@type": "CourseModule",
        name: "Java-programming-training-in-Abuja",
        description: "Learn core Java concepts, syntax, and data types.",
      },
      {
        "@type": "CourseModule",
        name: "Object-Oriented Programming (OOP)",
        description:
          "Understand OOP principles and apply them in Java programming.",
      },
      {
        "@type": "CourseModule",
        name: "Java Web Development",
        description:
          "Build web applications using Java technologies like Servlets, JSP, and Spring Framework.",
      },
      // Add more course modules as needed, following the same format
    ],
    hasCourseInstance: [
      {
        "@type": "CourseInstance",
        name: "Java programming training in Abuja,Nigeria", // Specific instance name
        startDate: "2024-01-01", // Start date of the instance
        endDate: "2024-12-01", // End date of the instance
        location: {
          "@type": "Place",
          name: "GMTsoftware Computer Training School Abuja Office", // Location name (optional)
        },
      },
      {
        // Online self-paced course that takes 2 days to complete.
        "@type": "CourseInstance",
        courseMode: "Blended",
        courseWorkload: "P2D",
      },
      // Add more objects for additional instances with different details
    ],
  };
  useEffect(() => {
    document.title =
      "Core Java Programming, Enterprise/Web/Cloud Development, an Workflow Automation Training in Abuja";
  }, []);
  return (
    <>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      <Navbar />
      <div className="container-fluid bg-dark text-white">
        <div className="container py-5 header_one">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p
                style={{
                  backgroundColor: "rgb(255, 153, 51)",
                  color: "#fff",
                  display: "inline",
                }}
              >
                LEARN FROM INDUSTRY LEADERS
              </p>
              <h1 className="text-white">
                Core Java Programming, Enterprise/Web/Cloud Development, and
                Workflow Automation
              </h1>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Gain expertise in Core Java, and master enterprise-level
                application development, cloud computing, and workflow
                automation. Learn to develop scalable, secure, and efficient
                systems that power modern businesses.
              </p>
              <div className="container my-4">
                <Link
                  to="/sign-up"
                  className="ml-3 btn btn-warning btn-md"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "rgb(255, 153, 51)",
                  }}
                >
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img
                src="https://images.unsplash.com/photo-1573497491208-6b1acb260507?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60"
                alt="Java Programming"
                className="img-fluid shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="py-4 w-75" style={{ fontWeight: 1000 }}>
          With Java being the backbone of enterprise applications, cloud
          computing, and automation, skilled Java developers are always in
          demand.
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Master Core Java Programming</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Learn the foundations of Java programming, from basic syntax to
              advanced object-oriented principles. Build secure, robust
              applications that can handle large-scale enterprise needs.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Develop Enterprise, Web, and Cloud Applications</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Dive deep into enterprise-level application development with Java
              EE, Spring Framework, and cloud platforms. Learn to build
              scalable, secure, and efficient web and cloud-based solutions that
              meet industry standards.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
            <h4>Automate Workflows with Java and Cloud Solutions</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Explore workflow automation and integration techniques that
              streamline business processes. Implement automated solutions using
              Java, cloud services, and enterprise platforms to boost
              productivity and operational efficiency.
            </p>
          </div>
        </div>
      </div>

      <div className="curriculum-section front_img shadow-lg">
        <div className="container text-white">
          <div className="row flex-row-reverse">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
              <h2
                className="display-2 font-weight-bolder pt-5"
                style={{ color: "#fff" }}
              >
                Curriculum Built for Enterprise-Ready Developers
              </h2>
              <h4 className="pt-5">
                <strong>Hands-On, Real-World Projects</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Engage in real-world enterprise projects to gain a comprehensive
                understanding of Java EE, cloud services, and automation
                workflows. Develop solutions that solve complex business
                problems and improve operational efficiency.
              </p>
              <h4>
                <strong>
                  Gain Proficiency in Cloud and Automation Solutions
                </strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Build and deploy enterprise-grade cloud applications using
                platforms like AWS and Google Cloud. Learn how to implement
                automation frameworks to streamline repetitive tasks and enhance
                system performance.
              </p>
              <h4>
                <strong>Self-Paced Learning for Busy Professionals</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Get started with preparatory lessons that cover everything from
                Java programming to cloud infrastructure. Go at your own pace
                with exercises and projects designed to build your skills
                progressively.
              </p>
              {/* <div className="py-4 text-center">
                <a href={Pdf} className="btn btn-dark btn-sm-center">
                  Download Curriculum <br />
                  <span>
                    <FaDownload size={20} />
                  </span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center my-auto">
            <img
              src="./images/student_testimonial_java.png"
              alt="Student Testimonial"
              width="100"
              className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
            />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <strong>Enterprise Developer</strong> <br /> Michael Johnson
            </p>
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <em>
                “This course gave me the practical knowledge I needed to build
                robust enterprise solutions. Learning Java, cloud integration,
                and automation workflows helped me elevate my skills and deliver
                better business outcomes.”
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container p-5">
          <h2 className="text-center">Flexible Payment Plans</h2>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Full Tuition</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Pay the full #200,000 upfront and gain access to additional
                resources, including advanced tutorials on enterprise
                development and cloud solutions.
              </p>
              <h4>#200,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Full Tuition
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Installments</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Opt for our installment plan and pay in two easy payments.
              </p>
              <h4>#150,000</h4> & <h4>#50,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Per Installment
              </p>
            </div>
          </div>
        </div>
      </div>
      <IconContext.Provider value={{ color: "#fff", size: 64 }}>
        <div className="pricing__section">
          <div className="pricing__wrapper text-center">
            <h2 className="py-3 text-center">
              Core Java Programming Course Prices
            </h2>
            <div className="pricing__container text-center">
              <Link
                to="/sign-up"
                className="pricing__container-card text-center"
              >
                <div className="pricing__container-cardInfo">
                  <div className="icon1">
                    <FaJava />
                  </div>
                  <h3>Java Dev</h3>
                  <h4>Installments </h4>
                  <h3>70% installment</h3>
                  <ul className="pricing__container-features">
                    <li>8 weeks</li>
                    <li>Abuja, Nigeria</li>
                  </ul>
                  <h4>Total</h4>
                  <h3>#200,000</h3>
                  <p>8 weeks</p>
                  <Link to="/signup">
                    <button className="btn bg-dark text-white">
                      Apply Now
                    </button>
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </IconContext.Provider>

      <div className="container text-center py-5">
        <h5>Need advice? Our Admissions team is here to help.</h5>
        <button className="btn btn-dark mb-2">
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:greatmindsoftware@gmail.com"
          >
            Contact Admissions
          </a>
        </button>
      </div>
      <Footer />
    </>
  );
};

export default JavaProgrammingComponent;
