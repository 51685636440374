import React, { useEffect } from "react";
import { FaDownload, FaHackerrank } from "react-icons/fa";
import Pdf from "../document/Cybersecurity.pdf";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Pricing.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

function CyberSecurity({ courseData }) {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: "Cybersecurity Training in Abuja, Nigeria", // Replace with actual name
    description: courseData.description, // Replace with actual description of the course
    provider: {
      "@type": "Organization",
      name: "GMTsoftware",
    },
    url: courseData.url, // Replace with actual URL of the course page
    sameAs: courseData.sameAs, // Replace with actual social media links
    image: courseData.image,
    offers: {
      "@type": "Offer",
      priceCurrency: "NGN", // Nigerian Naira
      price: 300000, // Adjust the price as needed
      availability: "https://schema.org/InStock",
      category: "Course",
    }, // Replace with actual image URL representing the course
    educationalUse: "FormalEducation", // Specify formal education use
    hasPart: [
      // Add course modules here
      {
        "@type": "CourseModule",
        name: "Network Security Fundamentals",
        description:
          "Learn about network security concepts, vulnerabilities, and mitigation techniques.",
      },
      {
        "@type": "CourseModule",
        name: "Ethical Hacking and Penetration Testing",
        description:
          "Gain hands-on experience in identifying and exploiting vulnerabilities to enhance security.",
      },
      // Add more course modules as needed, following the same format
    ],
    hasCourseInstance: [
      {
        "@type": "CourseInstance",
        name: "Cybersecurity Training in Abuja, Nigeria", // Specific instance name
        startDate: "2024-01-01", // Start date of the instance
        endDate: "2024-12-01", // End date of the instance
        location: {
          "@type": "Place",
          name: "GMTsoftware Computer Training School Abuja Office", // Location name (optional)
        },
      },
      {
        // Online self-paced course that takes 2 days to complete.
        "@type": "CourseInstance",
        courseMode: "Online",
        courseWorkload: "P2D",
      },
      // Add more objects for additional instances with different details
    ],
  };
  useEffect(() => {
    document.title =
      "python-for-cyber-security-and-ethical-hacking | Cybersecurity";
  }, []);
  return (
    <>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      <Navbar />
      <div className="container-fluid bg-dark text-white">
        <div className="container py-5 header_one">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p
                style={{
                  backgroundColor: "rgb(149, 29, 29)",
                  color: "#fff",
                  display: "inline",
                }}
              >
                LEARN FROM THE INDUSTRY'S TOP TALENT
              </p>
              <h1 className="text-white">
                Cybersecurity Professional Nanodegree
              </h1>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Gain hands-on experience in defending against cyber threats and
                mastering essential security protocols. Build a career in one of
                the most critical fields of the 21st century.
              </p>
              <div className="container my-4">
                <Link
                  to="/sign-up"
                  className="ml-3 btn btn-danger btn-md"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "rgb(149, 29, 29)",
                  }}
                >
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img
                src="./images/cyber.jpeg"
                alt="Cybersecurity"
                className="img-fluid shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="py-4 w-75" style={{ fontWeight: 1000 }}>
          According to recent cybersecurity reports, skilled professionals are
          in high demand to protect critical data and infrastructure from cyber
          threats.
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Develop Critical Skills in a High-Demand Field</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Equip yourself with the knowledge to protect networks, secure
              information systems, and safeguard sensitive data against
              sophisticated cyber attacks. Your journey starts here.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Join a Global Network of Cybersecurity Experts</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Connect with industry leaders and build your professional network
              within the global cybersecurity community. Unlock career
              opportunities through our alumni and partner network.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
            <h4>Learn on a Flexible Schedule</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              Join our comprehensive cybersecurity course online, or attend
              classes at our secure Abuja campus. Learn at your own pace while
              balancing your professional and personal commitments.
            </p>
          </div>
        </div>
      </div>

      <div className="curriculum-section front_img shadow-lg">
        <div className="container text-white">
          <div className="row flex-row-reverse">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
              <h2
                className="display-2 font-weight-bolder pt-5"
                style={{ color: "#fff" }}
              >
                Inside Our Cutting-Edge Cybersecurity Curriculum
              </h2>
              <h4 className="pt-5">
                <strong>Focused on Real-World Cybersecurity Challenges</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Learn how to combat modern cyber threats, protect critical
                infrastructures, and secure applications against malicious
                attacks. Our curriculum is designed to provide you with
                practical, hands-on experience.
              </p>
              <h4>
                <strong>Master Defensive and Offensive Cyber Techniques</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                From identifying vulnerabilities to deploying advanced security
                measures, gain the expertise needed to safeguard digital assets.
                Practice in simulated environments to develop defensive and
                offensive security skills.
              </p>
              <h4>
                <strong>Get Started with Self-Paced Prep Lessons</strong>
              </h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Before the course begins, explore cybersecurity fundamentals
                with self-paced lessons designed to set you up for success. Dive
                into topics like network security, encryption, and threat
                detection.
              </p>
              <div className="py-4 text-center">
                <a href={Pdf} className="btn btn-dark btn-sm-center">
                  Download Curriculum <br />
                  <span>
                    <FaDownload size={20} />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center my-auto">
            <img
              src="./images/student.png"
              alt="Student Testimonial"
              width="100"
              className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
            />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <strong>Cybersecurity Analyst</strong> <br /> Sarah Johnson
            </p>
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <em>
                “The Cybersecurity Nanodegree program provided me with the
                knowledge and tools to excel in my career. I now have the
                confidence to tackle any cyber threat that comes my way.”
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container p-5">
          <h2 className="text-center">Flexible Payment Options</h2>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4>Full Tuition</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Save 5% by paying the full #300,000 upfront and get access to
                exclusive resources.
              </p>
              <h4>#300,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Full Tuition
              </p>
            </div>
          </div>
        </div>
      </div>

      <IconContext.Provider value={{ color: "#fff", size: 64 }}>
        <div className="pricing__section">
          <div className="pricing__wrapper text-center">
            <h2 className="py-3 text-center">
              Our Cybersecurity Course Prices
            </h2>
            <div className="pricing__container text-center">
              <Link
                to="/sign-up"
                className="pricing__container-card text-center"
              >
                <div className="pricing__container-cardInfo">
                  <div className="icon1">
                    <FaHackerrank />
                  </div>
                  <h4>Cybersecurity</h4>

                  <ul className="pricing__container-features">
                    <li>10 weeks</li>
                    <li>Abuja, Nigeria</li>
                  </ul>
                  <h4>Total</h4>
                  <h3>#300,000</h3>
                  <Link to="/signup">
                    <button className="btn bg-dark text-white">
                      Apply Now
                    </button>
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </IconContext.Provider>

      <div className="container text-center py-5">
        <h5>Need advice? Our Admissions team is here to help.</h5>
        <button className="btn btn-dark mb-2">
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:contact@gmtsoftware.tech"
          >
            Contact Admissions
          </a>
        </button>
      </div>

      <Footer />
    </>
  );
}

export default CyberSecurity;
